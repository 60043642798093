































import Vue from "vue";
import content from "@/content.json";
import BeheerTable from "@/components/lib/BeheerTable.vue";

export default Vue.extend({
  name: "VerwijderComponent",
  components: { BeheerTable },
  data: () => ({
    page: content.pages.verwijder,
    links: content.links,
    error: "",
    aanleverStatusData: [],
  }),
  methods: {
    removeFile() {
      this.$request
        .post(
          `${this.$store.state.APIurl}/document/verwijder?document_id=${this.$store.state.user.document.id}`
        )
        .then((response: any) => {
          console.log(response.data)
          this.$store.dispatch("updateDocument", response.data);
          this.$store.dispatch("updateFindings", []);
          this.$router.push("/uploaden");
        })
        .catch((error: any) => {
          if (error.code == "ECONNABORTED") {
            this.error = content.common.timeout;
          } else {
            this.error = error.response.data.detail;
          }
        });
    },
    toUpload() {
      this.$router.push("/uploaden");
    },
    fetchAanleverStatus() {
      this.$request(
        `${this.$store.state.APIurl}/document/aanlever-status`
      ).then((response: any) => {
        this.aanleverStatusData = response.data;
      });
    },
  },
  mounted() {
    this.fetchAanleverStatus();
  },
});
